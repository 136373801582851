<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: 'providers.view',
              params: { id: $route.params.provider_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${loading_provider ? '' : `${provider.name} | `}${
                $route.meta.title
              } ${loading ? '' : `(${total_rows})`}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.benefits.create"
            tLabel="Migración"
            tPosition="bottom"
            icon="mdi-file-table"
            color="warning"
            size="x-small"
            :to="{
              name: 'prestaciones.migracion',
              params: { provider_id: $route.params.provider_id },
            }"
          />
          <BtnCircle
            v-if="login.permissions.benefits.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
          >
            <template v-slot:item.benefit_details[0].description="{ item }">
              {{ item.benefit_details[0].description }}
            </template>
            <template v-slot:item.description_total="{ item }">
              {{ item.benefit_details.length }}
            </template>
            <template v-slot:item.benefit_details[0].amount="{ item }">
              <span
                v-text="
                  numberFormat(parseFloat(item.benefit_details[0].amount))
                "
              />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    @click="handleView(item.id)"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "../../control";
import { index, show } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      provider_id: this.$route.params.provider_id,
      page_route: "prestaciones",
      login: this.$store.getters.getLogin,
      loading: true,
      data: [],
      total_rows: 0,
      provider: {},
      loading_provider: true,
      search: "",
      headers: [
        { text: "Tipo", filterable: true, value: "benefit_type.benefit_type" },
        { text: "Código", filterable: true, value: "code" },
        {
          text: "Descripción",
          filterable: true,
          value: "benefit_details[0].description",
        },
        {
          text: "Cant. desc.",
          filterable: true,
          value: "description_total",
        },
        { text: "Monto", filterable: true, value: "benefit_details[0].amount" },
        { text: "Acciones", value: "action", sortable: false },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleView(row_id) {
      this.$router.push({
        name: `${this.page_route}.view`,
        params: { provider_id: this.provider_id, id: row_id },
      });
    },
    numberFormat(v) {
      return Number(v.toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("providers", this.login.token, this.provider_id).then((response) => {
      this.provider = response;
      this.loading_provider = false;
    });

    index("benefits/provider/" + this.provider_id, this.login.token, []).then(
      (response) => {
        this.data = response.data;
        this.total_rows = this.data.length;
        this.loading = false;
      }
    );
  },
};
</script>