var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('BtnCircle',{attrs:{"tLabel":"Atrás","tPosition":"bottom","icon":"mdi-arrow-left","color":"grey darken-3","size":"x-small","to":{
            name: 'providers.view',
            params: { id: _vm.$route.params.provider_id },
          }}}),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            ("" + (_vm.loading_provider ? '' : ((_vm.provider.name) + " | ")) + (_vm.$route.meta.title) + " " + (_vm.loading ? '' : ("(" + _vm.total_rows + ")")))
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.login.permissions.benefits.create)?_c('BtnCircle',{attrs:{"tLabel":"Migración","tPosition":"bottom","icon":"mdi-file-table","color":"warning","size":"x-small","to":{
            name: 'prestaciones.migracion',
            params: { provider_id: _vm.$route.params.provider_id },
          }}}):_vm._e(),(_vm.login.permissions.benefits.create)?_c('BtnCircle',{attrs:{"tLabel":"Crear","tPosition":"bottom","icon":"mdi-plus","color":"success","size":"x-small","to":{ name: (_vm.page_route + ".store") }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.benefit_details[0].description",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.benefit_details[0].description)+" ")]}},{key:"item.description_total",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.benefit_details.length)+" ")]}},{key:"item.benefit_details[0].amount",fn:function(ref){
          var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(
                _vm.numberFormat(parseFloat(item.benefit_details[0].amount))
              )}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.handleView(item.id)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-eye-outline')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }